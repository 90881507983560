






























































































































































import { Component, Vue } from 'vue-property-decorator';

import InvestmentStrategyConfigureContributionViewModel
  from '@/vue-app/view-models/allianz-dashboard/access-more-strategies/investment-strategy-configure-contribution-view-model';

@Component({
  name: 'InvestmentStrategyConfigureContribution',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class InvestmentStrategyConfigureContribution extends Vue {
  configure_contribution_view_model = Vue.observable(
    new InvestmentStrategyConfigureContributionViewModel(this),
  );

  created() {
    this.configure_contribution_view_model.initialize();
  }
}
