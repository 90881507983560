import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';

import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';

import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';

// Domain
import AccessMoreStrategiesState
  from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class InvestmentStrategyConfigureContributionViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.allianz-dashboard.investment-strategy-configure-contribution';

  readonly i18n_form_errors_namespace = 'utils.form-rules';

  private readonly customer_id = sessionStorage.getItem('user_id');

  is_valid_form = false;

  recurrent_assigned_amount = 0;

  recurring_contribution_slider = 500;

  recurring_contribution_min_value = 500;

  recurring_contribution_max_value = 50000;

  new_recurrent_amount = 0;

  has_recurring_contributions = false;

  slider_step = 50;

  recurring_contribution = '0';

  inputs_rules = {
    recurring_contribution: [],
  }

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.inputs_rules.recurring_contribution = this.getRecurringContributionInputRules();
    this.state = this.manager_service.getAccessMoreStrategiesState();
  }

  initialize = async () => {
    await this.loadRecurrentAssignedAmount();
  }

  translate = (message: string, values?: Values, namespace = this.i18n_namespace) => this
    .translator.translate(`${namespace}.${message}`, values);

  get can_continue() {
    return (this.is_valid_form && !this.state.define_contribution_later)
      || this.state.define_contribution_later || this.state.is_loading;
  }

  get recurrent_assigned_amount_formatted() {
    return currencyFormat(this.recurrent_assigned_amount);
  }

  loadRecurrentAssignedAmount = async () => {
    try {
      const {
        id,
        amount,
        home_desire,
        monthly_collection_day,
      } = await this
        .get_allianz_account_query.execute({ customer_id: this.customer_id });
      this.state.recurring_contribution.id = id;
      this.recurrent_assigned_amount = amount || 0;
      this.has_recurring_contributions = home_desire;

      if (home_desire) {
        this.new_recurrent_amount = this.recurrent_assigned_amount;
        this.state.recurring_contribution.amount = amount?.toString() || '0';
        this.state.recurring_contribution.monthly_collection_day = monthly_collection_day || 0;
        this.state.current_recurrent_contribution = amount || 0;
      }
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.get_recurrent_assigned_amount'));
    }
  }

  sendNewRecurrentAssignedAmount = async () => {
    if (this.state.define_contribution_later) {
      this.view.$emit('endProcess');
    } else {
      this.state.recurring_contribution.amount = this.new_recurrent_amount.toString()
        .replaceAll(/[^\d.-]/g, '');
      this.state.goal_contribution = parseFloat(
        this.recurring_contribution.replaceAll(/[^\d.-]/g, ''),
      );
      this.view.$emit('nextStep', {});
    }
  }

  updateRecurringContribution = () => {
    this.recurring_contribution = this.recurring_contribution_slider.toString();
  }

  calculateNewRecurringContribution = () => {
    const recurring_contribution_parsed = parseFloat(
      this.recurring_contribution.replaceAll(/[^\d.-]/g, ''),
    );
    this.new_recurrent_amount = this.recurrent_assigned_amount + recurring_contribution_parsed;
  }

  defineContributionLater = () => {
    if (this.state.define_contribution_later) {
      this.state.current_recurrent_contribution = 0;
      this.state.goal_contribution = 0;
      this.recurring_contribution = '0';
      this.inputs_rules.recurring_contribution.length = 0;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.inputs_rules.recurring_contribution = this.getRecurringContributionInputRules();
    }
  }

  getRecurringContributionInputRules = () => [
    requiredRule,
    (value: string) => (
      (this.state.define_contribution_later || parseFloat(value.replace(/[^0-9.]/g, '')) >= this.recurring_contribution_min_value)
      || this.translate(
        'minimum_error',
        { value: '$500.00 MXN' },
        this.i18n_form_errors_namespace,
      )
    ),
    (value: string) => (
      (this.state.define_contribution_later || parseFloat(value.replace(/[^0-9.]/g, '')) <= this.recurring_contribution_max_value)
      || this.translate(
        'maximum_error',
        { value: '50,000.00 MXN' },
        this.i18n_form_errors_namespace,
      )
    ),
  ];

  prevStep = () => {
    this.view.$emit('prevStep');
  }
}
